
export default {
	name: 'sickParticulars',
	data() {
		return {
			patient: [],
			project: [],
			workList: [{
				id: 1,
				number: 1,
				name: '张蓓蓓',
				stage: '已完成',
				startTime: '2018-04-02',
				list: [{
					name: '病史询问',
					start: '已完成',
					time: '2019-1-1',
					member: 'crc张蓓蓓',
					img: 3,
					remark: 1
				}, {
					name: '病史询问',
					start: '任务延期',
					time: '2019-1-1',
					member: 'crc张犇',
					img: 0,
					remark: 1
				}]
			}, {
				id: 2,
				number: 2,
				name: '张犇',
				stage: '已废弃',
				startTime: '2018-04-02',
			},]
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.type = this.getCookie('type')
		this.getProPar()
	},

	methods: {
		toSickParticulars(index, list) {
			this.$router.push({
				name: 'sickParticulars',
				params: {
					id: list[index].id
				}
			})
		},
		getProPar() {
			var _this = this;
			_this.$http.post('/API/PROJECT/inspectionItemByPatient', {
				pid: _this.$route.params.id,
				patientId: _this.$route.params.patientId
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.patient = response.data.data.patient;
						_this.project = response.data.data.project;
						_this.workList = response.data.data.workList;
					}
				})
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},
		toPl() {
			this.$router.go(-1)
		},
	}
}
